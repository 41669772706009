import React from "react"
import axios from "axios"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BOOKMARKS_API_PUBLIC_TOKEN = "78d673a1-71df-417c-aed5-033a98eff757"

/*
 * TODO
 * - Sort by date
 * - Add the date as a subtitle and add good descriptions
 */

export default ({ location }) => {
  const [loading, setLoading] = React.useState(true)
  const [links, setLinks] = React.useState([])

  React.useEffect(() => {
    axios
      .get(
        `https://api.chrona.io/bookmarks?publicToken=${BOOKMARKS_API_PUBLIC_TOKEN}`
      )
      .then(({ data }) => {
        console.log(data)
        setLinks(
          data
            .filter(d => !d.archived)
            .map(d => ({
              title: d.title,
              url: d.href,
              created_at: d.created_at,
            }))
        )
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Layout location={location} title={`Links`}>
      <SEO
        title="Links"
        keywords={[`links`, `jmfurlott`, `javascript`, `react`]}
      />
      <h2>Links</h2>
      <ul>
        {links
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(l => (
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={l.url}
                style={{ textDecoration: "underline", color: "#BFEBBF" }}
              >
                {l.title}
              </a>
            </li>
          ))}
      </ul>
    </Layout>
  )
}
